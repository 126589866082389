import { formatDate } from '@angular/common';
import { FormGroup } from '@angular/forms';
import * as _ from 'lodash';
import { SupportedLanguages } from './types/language.type';
import { TranslateService } from '@ngx-translate/core';
import { pipe, Subject } from 'rxjs';
import { tap } from 'rxjs/operators';

export function removeTrailingSpaceFromObjectProps(params: any) {
   if (typeof params == 'object' && params) {
      Object.keys(params).map(k => {
         if (typeof params[k] == 'string') {
            params[k] = _.trimEnd(params[k]);
         }

         if (typeof params[k] == 'object') {
            removeTrailingSpaceFromObjectProps(params[k]);
         }
      });
   }
}

export function findAndSetAutosuggestValue(
   list: any[],
   value: string,
   baseArray: boolean,
   form: FormGroup,
   formControlName: string,
   key?: string,
): void {
   if (baseArray) {
      let found = list.find(item => item.toLowerCase() === value.toLowerCase());
      if (found && found.toLowerCase() === value.toLowerCase() && found !== value) {
         form.get(formControlName)?.setValue(found);
      }
   } else {
      if (!key) {
         return;
      }
      let found = list.find(item => item[key].toLowerCase() === value.toLowerCase());
      if (found && found[key].toLowerCase() === value.toLowerCase() && found[key] !== value) {
         form.get(formControlName)?.setValue(found[key]);
      }
   }
   return;
}

export function convertDate(date: string | Date): string {
   const d = new Date(date);
   return (
      d.getFullYear() +
      '-' +
      (d.getMonth() + 1 < 10 ? '0' + (d.getMonth() + 1) : d.getMonth() + 1) +
      '-' +
      (d.getDate() < 10 ? '0' + d.getDate() : d.getDate())
   );
}

export function convertDateAndTime(date: Date | string): string {
   const newDate = new Date(date);
   return formatDate(newDate, 'YYYY-MM-dd HH:mm:ss', 'en-US');
}

export function checkStringIsDate($event: any): Date | undefined {
   if ($event.srcElement && $event.srcElement.value && $event.srcElement.value.length >= 6) {
      let year: number;
      let month: number;
      let day: number;
      const input: string = $event.srcElement.value;
      if (input.includes('-') || input.includes('.') || input.includes('/') || input.includes('\\'))
         return;
      try {
         year = Number(input.substring(0, 4));
         if (input.length === 8) {
            month = Number(input.substring(4, 6)) - 1;
            day = Number(input.substring(6));
         } else {
            month = Number(input.substring(4, 5)) - 1;
            day = Number(input.substring(5));
         }
         return new Date(year, month, day);
      } catch (e) {
         return;
      }
   }
   return;
}
export function getGraduations() {
   let yearFrom = +new Date().getFullYear().toString().substring(2) - 1;
   const semesters = [];
   const afterFirstSemester = new Date().getMonth() > 6;
   let currSem = afterFirstSemester ? 2 : 1;
   for (let i = 0; i < 33; i++) {
      semesters.push(`${yearFrom}/${yearFrom + 1}/${currSem}`);

      if (afterFirstSemester) {
         if (i % 2 == 0) yearFrom++;
      } else {
         if (i % 2 == 1) yearFrom++;
      }
      //change semester
      if (currSem == 1) currSem = 2;
      else currSem = 1;
   }
   return semesters;
}

export function depoLangToSupportedLang(
   depoLang: 'HU' | 'EN' | undefined,
): SupportedLanguages | undefined {
   switch (depoLang) {
      case 'EN':
         return 'en';
      case 'HU':
         return 'hu';
      default:
         return undefined;
   }
}

export function supportedLangToDepoLang(
   supportedLang: SupportedLanguages | undefined,
): 'HU' | 'EN' | undefined {
   switch (supportedLang) {
      case 'en':
         return 'EN';
      case 'hu':
         return 'HU';
      default:
         return undefined;
   }
}

export function tranlateToLanguage(
   translateService: TranslateService,
   key: string,
   lang: SupportedLanguages,
): string {
   return translateService.getParsedResult(translateService.translations[lang], key);
}

export function startSpinner(subject: Subject<boolean>, ends = true) {
   return pipe(
      tap<any>(
         value => {
            if (value) {
               subject.next(ends);
            }
         },
         () => {
            subject.next(false);
         },
         () => {
            subject.next(false);
         },
      ),
   );
}

export function searchObj(obj: any, query: string): boolean {
   for (var key in obj) {
      var value = (obj as any)[key];
      if (typeof value === 'object') {
         if (searchObj(value, query)) {
            return true;
         } else continue;
      }

      //TODO kiokosítani
      if (((value || '').toString() as string).toLowerCase().includes(query)) {
         return true;
      }
   }
   return false;
}
