import { animate, state, style, transition, trigger } from '@angular/animations';
import { LiveAnnouncer } from '@angular/cdk/a11y';
import { formatDate } from '@angular/common';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { AbstractControl, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import {
   MatSnackBar,
   MatSnackBarHorizontalPosition,
   MatSnackBarVerticalPosition,
} from '@angular/material/snack-bar';
import { Sort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import * as _ from 'lodash';
import { BehaviorSubject, iif, Observable, of, Subject } from 'rxjs';
import { debounceTime, map, switchMap, takeUntil } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { MissingDataDialogComponent } from '../admin/missing-data-dialog/missing-data-dialog.component';
import { UuidType } from '../admin/missing-data-dialog/uuid-type.enum';
import { AppComponent } from '../app.component';
import { routes } from '../config/routes.config';
import { LoginService } from '../login/login.service';
import { PersonGrade } from '../modules/studies/studies.grades';
import { StudiesService } from '../modules/studies/studies.service';
import {
   checkStringIsDate,
   convertDate,
   convertDateAndTime,
   depoLangToSupportedLang,
   findAndSetAutosuggestValue,
   getGraduations,
   searchObj,
   startSpinner,
} from '../shared/helper-functions';
import { distinctUntilChangedAndNotify } from '../shared/operators/distinct-until-changed-and-notify';
import { AuthService } from '../shared/services/auth.service';
import { MediaService } from '../shared/services/media.service';
import { MembershipValidateUpdateForm } from './interfaces/mem-val-update-form.interface';
import {
   FormType,
   MembershipValidationData,
   ValidationStatus,
} from './interfaces/membership-validation-data.interface';
import { SupplementsService } from './supplements.service';

type BoxType = keyof typeof Boxes;

enum Boxes {
   waitSupplements = 'waitSupplements',
   waitValidation = 'waitValidation',
   revValidation = 'revValidation',
   revSupplements = 'revSupplements',
   synced = 'synced',
   trash = 'trash',
}

@Component({
   selector: 'app-supplements',
   templateUrl: './supplements.component.html',
   styleUrls: ['./supplements.component.css'],
   animations: [
      trigger('detailExpand', [
         state('collapsed', style({ height: '0px', minHeight: '0' })),
         state('expanded', style({ height: '*' })),
         transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
      ]),
   ],
})
export class SupplementsComponent implements OnInit, OnDestroy {
   horizontalPosition: MatSnackBarHorizontalPosition = 'center';

   verticalPosition: MatSnackBarVerticalPosition = 'top';

   Boxes = Boxes;

   destroyed$ = new Subject<void>();

   graduations = getGraduations();

   currentFilter: BoxType = 'waitSupplements';

   displayedColumns: string[] = [
      'uuid',
      'lastname',
      'firstname',
      'dateOfBirth',
      'taxNumber',
      'email',
      'createdAt',
      'syncDate',
   ];

   dataSource = new MatTableDataSource<MembershipValidationData>();

   expandedElement: MembershipValidationData | undefined;

   institutions$: Observable<string[]>;

   institutionSpinner$: BehaviorSubject<boolean>;

   grades = Object.keys(PersonGrade) as (keyof typeof PersonGrade)[];

   PersonGrade = PersonGrade;

   boxes: { [key in BoxType]: MembershipValidationData[] } = {
      revSupplements: [],
      revValidation: [],
      synced: [],
      trash: [],
      waitSupplements: [],
      waitValidation: [],
   };

   touchUi$ = new BehaviorSubject(false);

   updateForm: FormGroup;

   course1Spinner$: BehaviorSubject<boolean>;

   course2Spinner$: BehaviorSubject<boolean>;

   course3Spinner$: BehaviorSubject<boolean>;

   courses1$: Observable<{ course_name: string }[]>;

   courses2$: Observable<{ course_name: string }[]>;

   courses3$: Observable<{ course_name: string }[]>;

   faculties$: Observable<string[]>;

   facultySpinner$: BehaviorSubject<boolean>;

   missingDataDialogRef: MatDialogRef<MissingDataDialogComponent> | null = null;

   downloadUrl = routes.membershipValidationDocuments;

   uploadUrl = routes.membershipValidationUpload;

   isMoveToTrashShown = false;

   isMoveToTrashDisabled = false;

   isMoveToRevisedShown = false;

   isMoveToRevisedDisabled = false;

   isSendMissingDataEmailShown = false;

   isSendMissingDataEmailDisabled = false;

   isUpdateFormDisabled = false;

   filterForm = new FormGroup({
      filter: new FormControl(),
   });

   formSynced$: Subject<boolean> = new Subject();

   formSynced = true;

   initialFormData: MembershipValidateUpdateForm = {
      email: '',
      faculty: '',
      grade: '',
      graduationDate: '',
      institution: '',
      membershipValidDate: new Date(),
      studentIdNumber: '',
      studentStatus: '',
      course1: '',
      course2: '',
      course3: '',
   };

   constructor(
      private route: ActivatedRoute,
      private router: Router,
      private membershipValidationService: SupplementsService,
      private authService: AuthService,
      private mediaService: MediaService,
      private loginService: LoginService,
      private studiesService: StudiesService,
      private missingDataDialog: MatDialog,
      private _liveAnnouncer: LiveAnnouncer,
      private snackBar: MatSnackBar,
      private translate: TranslateService,
   ) {
      this.mediaService.screen$
         .pipe(
            takeUntil(this.destroyed$),
            map(screen => {
               return (
                  screen === 'mobileM' ||
                  screen === 'phoneLandscape' ||
                  screen === 'phonePortrait' ||
                  screen === 'tabletPortrait'
               );
            }),
         )
         .subscribe(this.touchUi$);

      this.updateForm = new FormGroup({
         membershipValidDate: new FormControl('', Validators.required),
         studentStatus: new FormControl(''),
         graduationDate: new FormControl('', Validators.required),
         institution: new FormControl('', Validators.required),
         email: new FormControl('', [
            Validators.required,
            Validators.email,
            Validators.maxLength(100),
         ]),
         course1: new FormControl(''),
         course2: new FormControl(''),
         course3: new FormControl(''),
         grade: new FormControl(''),
         faculty: new FormControl(''),
         studentIdNumber: new FormControl('', Validators.required),
      });

      this.updateFormState();

      this.institutionSpinner$ = new BehaviorSubject<boolean>(false);

      this.institutions$ = this.updateForm.controls.institution.valueChanges.pipe(
         takeUntil(this.destroyed$),
         startSpinner(this.institutionSpinner$),
         debounceTime(1000),
         distinctUntilChangedAndNotify(this.institutionSpinner$, false),
         switchMap(name =>
            iif(
               () => name && name.length > 1,
               this.studiesService.getSchool(name, AppComponent.currentLang),
               of([]),
            ),
         ),
         startSpinner(this.institutionSpinner$, false),
      );

      this.facultySpinner$ = new BehaviorSubject<boolean>(false);
      this.faculties$ = this.updateForm.controls.faculty.valueChanges.pipe(
         takeUntil(this.destroyed$),
         startSpinner(this.facultySpinner$),
         debounceTime(1000),
         distinctUntilChangedAndNotify(this.facultySpinner$, false),
         switchMap(name =>
            iif(
               () => name && name.length > 1,
               this.studiesService.getFaculty(name, AppComponent.currentLang),
               of([]),
            ),
         ),
         startSpinner(this.facultySpinner$, false),
      );

      this.course1Spinner$ = new BehaviorSubject<boolean>(false);
      this.course2Spinner$ = new BehaviorSubject<boolean>(false);
      this.course3Spinner$ = new BehaviorSubject<boolean>(false);

      this.courses1$ = this.registerCoursesObservers(
         this.updateForm.controls.course1,
         this.course1Spinner$,
      );
      this.courses2$ = this.registerCoursesObservers(
         this.updateForm.controls.course2,
         this.course2Spinner$,
      );
      this.courses3$ = this.registerCoursesObservers(
         this.updateForm.controls.course3,
         this.course3Spinner$,
      );
   }

   ngOnInit(): void {
      this.dataSource.filterPredicate = (data: MembershipValidationData, filter: string) => {
         return searchObj(data, filter);
      };

      this.getData();
   }

   subscribeToFormSync() {
      this.formSynced = true;
      this.updateForm.valueChanges
         .pipe(
            takeUntil(this.formSynced$), // Subject that stops you from listening to the form changes
            debounceTime(500), // adds half-second for debouncing changes
         )
         .subscribe({
            next: (changes: MembershipValidateUpdateForm) => {
               this.formSynced = this.isFormValuesEquals(this.initialFormData, changes);
            },
         });
   }

   unsubscribeFromFormSync() {
      this.formSynced$.next(true);
   }

   applyFilter(event: Event) {
      const filterValue = (event.target as HTMLInputElement).value;
      this.dataSource.filter = filterValue.trim().toLowerCase();
   }

   /** Announce the change in sort state for assistive technology. */
   announceSortChange(sortState: Sort | any) {
      // This example uses English messages. If your application supports
      // multiple language, you would internationalize these strings.
      // Furthermore, you can customize the message to add additional
      // details about the values being sorted.
      if (sortState.direction) {
         this._liveAnnouncer.announce(`Sorted ${sortState.direction}ending`);
      } else {
         this._liveAnnouncer.announce('Sorting cleared');
      }
   }

   registerCoursesObservers(control: AbstractControl, courseSpinner: BehaviorSubject<boolean>) {
      return control.valueChanges.pipe(
         takeUntil(this.destroyed$),
         startSpinner(courseSpinner),
         debounceTime(1000),
         distinctUntilChangedAndNotify(courseSpinner, false),
         switchMap(name =>
            iif(
               () => name && name.length > 1,
               this.studiesService.getCourse(name, AppComponent.currentLang),
               of([]),
            ),
         ),
         map(courses => courses.map(m => m.course_name)),
         startSpinner(courseSpinner, false),
      );
   }

   async getData() {
      this.membershipValidationService.listMembershipValidations().subscribe(res => {
         this.clearBoxes();
         res.map(item => {
            this.boxes[this.getBox(item)].push(item);
         });
         this.dataSource.data = this.boxes[this.currentFilter];
      });
   }

   getBox(item: MembershipValidationData): Boxes {
      switch (item.status) {
         case ValidationStatus.SYNCED:
            return Boxes.synced;
         case ValidationStatus.FINISHED:
            if (item.type === FormType.DATA_SUPPLEMENTATION) {
               return Boxes.waitSupplements;
            } else if (item.type === FormType.MEMBERSHIP_VALIDATION) {
               return Boxes.waitValidation;
            } else {
               throw new Error(`Invalid formType :  ${item.type} for uuid:  ${item.uuid}`);
            }
         case ValidationStatus.REVISED_WAITING_FOR_SYNC:
            if (item.type === FormType.DATA_SUPPLEMENTATION) {
               return Boxes.revSupplements;
            } else if (item.type === FormType.MEMBERSHIP_VALIDATION) {
               return Boxes.revValidation;
            } else {
               throw new Error(`Invalid formType :  ${item.type} for uuid:  ${item.uuid}`);
            }

         case ValidationStatus.TRASH:
            return Boxes.trash;

         default:
            throw new Error(`Invalid state :  ${item.type} for uuid:  ${item.uuid}`);
      }
   }

   clearBoxes() {
      this.boxes = {
         revSupplements: [],
         revValidation: [],
         synced: [],
         trash: [],
         waitSupplements: [],
         waitValidation: [],
      };
   }

   onFilterStatus(box: BoxType) {
      this.currentFilter = box;

      this.router.navigate([], {
         relativeTo: this.route,
         queryParams: {
            box,
         },
         queryParamsHandling: 'merge',
      });

      this.dataSource.data = this.boxes[box];

      this.updateFormState();
   }

   onExpand(element: MembershipValidationData) {
      this.updateForm.reset();
      if (this.expandedElement?.uuid === element.uuid) {
         this.expandedElement = undefined;
         this.unsubscribeFromFormSync();
      } else {
         this.expandedElement = element;
         if (!this.expandedElement.details) {
            this.membershipValidationService
               .getDetailedList(this.expandedElement.uuid, AppComponent.currentLang)
               .subscribe(
                  res => {
                     if (!environment.production) {
                        console.log(res);
                     }
                     if (this.expandedElement) {
                        this.expandedElement.details = res;
                        this.setUpdateFormData();
                        this.subscribeToFormSync();
                     }
                  },
                  () => {
                     if (this.expandedElement) this.expandedElement.details = null;
                  },
               );
         } else {
            this.setUpdateFormData();
         }
      }
   }

   //pass values for the form controls:
   setUpdateFormData() {
      this.refreshInitialFormData();

      this.updateForm
         .get('membershipValidDate')
         ?.setValue(this.initialFormData.membershipValidDate);
      this.updateForm.get('studentStatus')?.setValue(this.initialFormData.studentStatus);
      this.updateForm.get('graduationDate')?.setValue(this.initialFormData.graduationDate);
      this.updateForm.get('institution')?.setValue(this.initialFormData.institution);
      this.updateForm.get('email')?.setValue(this.initialFormData?.email);
      this.updateForm.get('course1')?.setValue(this.initialFormData.course1);
      this.updateForm.get('course2')?.setValue(this.initialFormData.course2);
      this.updateForm.get('course3')?.setValue(this.initialFormData.course3);
      this.updateForm.get('grade')?.setValue(this.initialFormData.grade);
      this.updateForm.get('faculty')?.setValue(this.initialFormData.faculty);
      this.updateForm.get('studentIdNumber')?.setValue(this.initialFormData.studentIdNumber);
   }

   convertDate(date: string | Date): string {
      return convertDate(date);
   }

   hasResource(resource: string): boolean {
      return this.authService.hasResource(resource);
   }

   displayBod(bod: string) {
      try {
         return new Date(bod).toISOString().slice(0, 10);
      } catch (_) {
         return '';
      }
   }

   membershipValidDateChange($event: any) {
      const result = checkStringIsDate($event);
      if (result) {
         this.updateForm.controls.membershipValidDate.setValue(result);
      }
   }

   convertDateAndTime(date: string | Date): string {
      return convertDateAndTime(date);
   }

   ngOnDestroy(): void {
      this.destroyed$.next();
      this.destroyed$.complete();
   }

   institutionChanged(event: any) {
      if (event.target) {
         this.studiesService
            .getSchool(event.target.value.trim(), AppComponent.currentLang)
            .subscribe((schools: string[]) => {
               if (!environment.production) console.log(schools);
               findAndSetAutosuggestValue(
                  schools,
                  event.target.value.trim(),
                  true,
                  this.updateForm,
                  'institution',
               );
            });
      }
   }

   course1Changed(event: any) {
      this.courseChanged(event, 'course1');
   }

   course2Changed(event: any) {
      this.courseChanged(event, 'course1');
   }

   course3Changed(event: any) {
      this.courseChanged(event, 'course1');
   }

   courseChanged(event: any, formControlName: string) {
      if (event.target) {
         this.studiesService
            .getCourse(event.target.value.trim(), AppComponent.currentLang)
            .subscribe((courses: { course_name: string }[]) => {
               if (!environment.production) console.log(courses);
               findAndSetAutosuggestValue(
                  courses,
                  event.target.value.trim(),
                  false,
                  this.updateForm,
                  formControlName,
                  'course_name',
               );
            });
      }
   }

   facultyChanged(event: any) {
      if (event.target) {
         this.studiesService
            .getFaculty(event.target.value.trim(), AppComponent.currentLang)
            .subscribe((faculties: string[]) => {
               findAndSetAutosuggestValue(
                  faculties,
                  event.target.value.trim(),
                  true,
                  this.updateForm,
                  'faculty',
               );
            });
      }
   }

   async onSubmit() {
      if (!this.expandedElement || !this.expandedElement.uuid) return;
      const uuid = this.expandedElement.uuid;
      const id = this.expandedElement.id;
      const selectedElement = this.expandedElement;

      this.membershipValidationService
         .syncronize({ id, uuid, ...this.updateForm.value })
         .subscribe(() => {
            if (selectedElement.type == FormType.DATA_SUPPLEMENTATION) {
               let removed = this.boxes.revSupplements.splice(
                  this.boxes.revSupplements.indexOf(selectedElement),
                  1,
               );
               if (this.boxes.revSupplements.indexOf(selectedElement) === -1) {
                  removed = this.boxes.waitSupplements.splice(
                     this.boxes.waitSupplements.indexOf(selectedElement),
                     1,
                  );
               }
               removed.map(r => (r.status = ValidationStatus.SYNCED));
               removed.map(r => (r.syncDate = new Date()));
               this.boxes.synced.splice(0, 0, ...removed);
            } else if (selectedElement.type == FormType.MEMBERSHIP_VALIDATION) {
               let removed = this.boxes.revValidation.splice(
                  this.boxes.revValidation.indexOf(selectedElement),
                  1,
               );
               if (this.boxes.revSupplements.indexOf(selectedElement) === -1) {
                  removed = this.boxes.waitValidation.splice(
                     this.boxes.waitValidation.indexOf(selectedElement),
                     1,
                  );
               }
               removed.map(r => (r.status = ValidationStatus.SYNCED));
               removed.map(r => (r.syncDate = new Date()));
               this.boxes.synced.splice(0, 0, ...removed);
            }
            this.expandedElement = undefined;
            this.onFilterStatus(this.currentFilter);

            this.snackBar.open(this.translate.instant('supplements.sync-success'), 'Ok', {
               horizontalPosition: this.horizontalPosition,
               verticalPosition: this.verticalPosition,
               duration: 3000,
            });
         });
   }

   async onRevised() {
      if (!this.expandedElement || !this.expandedElement.uuid) return;
      await this.onUpdate();
      const uuid = this.expandedElement.uuid;
      const selectedElement = this.expandedElement;
      this.expandedElement = undefined;
      this.membershipValidationService.markAsRevised(uuid).subscribe(() => {
         if (selectedElement.type == FormType.DATA_SUPPLEMENTATION) {
            const removed = this.boxes.waitSupplements.splice(
               this.boxes.waitSupplements.indexOf(selectedElement),
               1,
            );
            removed.map(r => (r.status = ValidationStatus.REVISED_WAITING_FOR_SYNC));
            this.boxes.revSupplements.push(...removed);
         } else if (selectedElement.type == FormType.MEMBERSHIP_VALIDATION) {
            const removed = this.boxes.waitValidation.splice(
               this.boxes.waitValidation.indexOf(selectedElement),
               1,
            );
            removed.map(r => (r.status = ValidationStatus.REVISED_WAITING_FOR_SYNC));

            this.boxes.revValidation.push(...removed);
         }
         this.onFilterStatus(this.currentFilter);
      });
      //after marked as revised, refresh ui
   }

   onMissingDataClicked() {
      this.missingDataDialogRef = this.missingDataDialog.open(MissingDataDialogComponent, {
         data: {
            //personId: this.expandedElement?.id,
            id: {
               uuid: this.expandedElement?.uuid,
               type: UuidType.membershipValidation,
            },
            language: depoLangToSupportedLang(this.expandedElement?.language),
         },
      });
   }

   async onTrash() {
      if (!this.expandedElement || !this.expandedElement.uuid) return;

      const uuid = this.expandedElement.uuid;
      const selectedElement = this.expandedElement;
      this.membershipValidationService.markAsTrash(uuid).subscribe(() => {
         const removed = this.getAndRemoveItemFromAnyBox(selectedElement);

         if (removed) {
            removed.map(r => (r.status = ValidationStatus.TRASH));
            this.boxes.trash.push(...removed);
         }
         this.expandedElement = undefined;
         this.onFilterStatus(this.currentFilter);
      });
   }

   onRefresh() {
      if (this.expandedElement) {
         const selectedElement = this.expandedElement;
         this.expandedElement = undefined;
         this.onExpand(selectedElement);
      } else {
         console.error('expandedElement is undefined');
      }
   }

   getAndRemoveItemFromAnyBox(elementToRemove: MembershipValidationData) {
      let element: MembershipValidationData[] | undefined;
      for (let k of Object.keys(Boxes)) {
         if ((this.boxes as any)[k].indexOf(elementToRemove) > -1) {
            element = (this.boxes as any)[k].splice(
               (this.boxes as any)[k].indexOf(elementToRemove),
               1,
            );
            break;
         }
      }
      return element;
   }

   updateFormState() {
      const formDisabled = !this.loginService.isLoggedIn() || this.currentFilter == Boxes.synced;

      this.setControlState(
         this.updateForm.controls.membershipValidDate,
         !this.hasResource('supplements.edit-valid-date') || formDisabled,
      );

      this.setControlState(
         this.updateForm.controls.studentStatus,
         !this.hasResource('supplements.edit-student-status') || formDisabled,
      );
      this.setControlState(
         this.updateForm.controls.graduationDate,
         !this.hasResource('supplements.edit-graduation-time') || formDisabled,
      );
      this.setControlState(
         this.updateForm.controls.institution,
         !this.hasResource('supplements.edit-institution-name') || formDisabled,
      );

      const emailEditDisabled = !(
         (this.currentFilter == Boxes.trash &&
            this.hasResource('supplements.edit-email-in-trash')) ||
         (![Boxes.synced.toString(), Boxes.trash].includes(this.currentFilter) &&
            this.hasResource('supplements.edit-email'))
      );

      this.setControlState(this.updateForm.controls.email, emailEditDisabled);
      this.setControlState(
         this.updateForm.controls.course1,
         !this.hasResource('supplements.edit-course') || formDisabled,
      );
      this.setControlState(
         this.updateForm.controls.course2,
         !this.hasResource('supplements.edit-course') || formDisabled,
      );
      this.setControlState(
         this.updateForm.controls.course3,
         !this.hasResource('supplements.edit-course') || formDisabled,
      );

      this.setControlState(
         this.updateForm.controls.grade,
         !this.hasResource('supplements.edit-grade') || formDisabled,
      );
      this.setControlState(
         this.updateForm.controls.faculty,
         !this.hasResource('supplements.edit-faculty') || formDisabled,
      );
      this.setControlState(
         this.updateForm.controls.studentIdNumber,
         !this.hasResource('supplements.edit-student-id-num') || formDisabled,
      );
      this.isMoveToTrashShown = [
         Boxes.revSupplements.toString(),
         Boxes.revValidation,
         Boxes.waitSupplements,
         Boxes.waitValidation,
      ].includes(this.currentFilter);

      this.isMoveToTrashDisabled = !(
         ([Boxes.revSupplements.toString(), Boxes.revValidation].includes(this.currentFilter) &&
            this.hasResource('supplements.move-to-trash-from-revised')) ||
         ([Boxes.waitSupplements.toString(), Boxes.waitValidation].includes(this.currentFilter) &&
            this.hasResource('supplements.move-to-trash-from-wait'))
      );

      this.isMoveToRevisedShown = [Boxes.waitSupplements.toString(), Boxes.waitValidation].includes(
         this.currentFilter,
      );

      this.isMoveToRevisedDisabled = !this.hasResource('supplements.move-to-revised');

      this.isSendMissingDataEmailShown = this.currentFilter != Boxes.synced;

      this.isSendMissingDataEmailDisabled = !(
         (this.currentFilter == Boxes.trash &&
            this.hasResource('supplements.send-missing-data-email-trash')) ||
         (![Boxes.synced.toString(), Boxes.trash].includes(this.currentFilter) &&
            this.hasResource('supplements.send-missing-data-email'))
      );

      this.isUpdateFormDisabled =
         formDisabled ||
         ![
            'supplements.edit-valid-date',
            'supplements.edit-student-status',
            'supplements.edit-graduation-time',
            'supplements.edit-institution-name',
            'supplements.edit-email',
            'supplements.edit-course',
            'supplements.edit-grade',
            'supplements.edit-faculty',
            'supplements.edit-student-id-num',
         ].some(v => {
            return this.hasResource(v);
         });
   }

   setControlState(control: AbstractControl, disabled: boolean) {
      if (disabled) {
         control.disable();
      } else {
         control.enable();
      }
   }

   async onUpdate() {
      if (!this.expandedElement || !this.expandedElement.uuid) return;
      const uuid = this.expandedElement.uuid;
      const data = { uuid, ...this.updateForm.value };
      let updatePromise;
      if (!this.hasResource('supplements.update-admin')) {
         //manager

         updatePromise = this.membershipValidationService.updateManager(data);
      } else {
         updatePromise = this.membershipValidationService.updateAdmin(data);
      }
      await updatePromise.then(() => {
         this.snackBar.open(this.translate.instant('supplements.saved-success'), 'Ok', {
            horizontalPosition: this.horizontalPosition,
            verticalPosition: this.verticalPosition,
            duration: 3000,
         });
         this.formSynced = true;
      });
      const refreshedData = await this.membershipValidationService.getOne(uuid);

      _.merge(this.expandedElement, refreshedData);
      this.setUpdateFormData();
   }

   isFormValuesEquals(
      orig: MembershipValidateUpdateForm,
      curr: MembershipValidateUpdateForm,
   ): boolean {
      return (
         orig.course1 == curr.course1 &&
         orig.course2 == curr.course2 &&
         orig.course3 == curr.course3 &&
         orig.email == curr.email &&
         orig.faculty == curr.faculty &&
         orig.grade == curr.grade &&
         orig.graduationDate == curr.graduationDate &&
         orig.institution == curr.institution &&
         formatDate(orig.membershipValidDate, 'yyyy-MM-dd', 'en') ==
            formatDate(curr.membershipValidDate, 'yyyy-MM-dd', 'en') &&
         orig.studentIdNumber == curr.studentIdNumber &&
         orig.studentStatus == curr.studentStatus
      );
   }

   refreshInitialFormData() {
      if (this.expandedElement && this.expandedElement.details) {
         this.initialFormData = {
            membershipValidDate: new Date(
               this.expandedElement.membershipValidDate ||
                  this.expandedElement?.details.ervenyesseg,
            ),
            studentStatus: this.expandedElement.studentStatus
               ? this.expandedElement.studentStatus
               : this.expandedElement.details.passziv
               ? 'PASSIVE'
               : 'ACTIVE',
            graduationDate:
               this.expandedElement.graduationDate ||
               this.expandedElement?.details?.graduationDate ||
               '',
            institution:
               this.expandedElement.institution ?? (this.expandedElement?.details.schoolName || ''),
            course1: this.expandedElement?.course1 ?? this.expandedElement?.details?.course1Name,
            course2: this.expandedElement?.course2 ?? this.expandedElement?.details?.course2Name,
            course3: this.expandedElement?.course3 ?? this.expandedElement?.details?.course3Name,
            grade: this.expandedElement?.grade ?? this.expandedElement?.details?.evfolyam,
            faculty:
               this.expandedElement?.faculty ?? (this.expandedElement?.details?.facultyName || ''),
            studentIdNumber:
               this.expandedElement?.studentIdNumber ??
               this.expandedElement?.details.diakigazolvany,
            email: this.expandedElement.email,
         };
      }
   }
}
